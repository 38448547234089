<template>
  <span>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>{{
            activatorIcon ? activatorIcon : "mdi-cloud-upload-outline"
          }}</v-icon>
        </v-btn>
      </template>

      <v-card width="100%" class="mx-auto pa-6">
        <v-card-title>
          <span>Upload New Map</span>
          <v-spacer />
          <v-btn @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-model="valid">
            <v-text-field
              label="Map Name"
              :rules="[rules.required]"
              v-model="mapName"
            />
            <v-file-input
              v-model="file"
              placeholder="Upload your map"
              label="Choose an image file to upload"
              accept="image/*"
              @change="getImageDimensions"
              :rules="[rules.required]"
            />
            <div
              class="d-flex justify-space-around"
              v-if="file && mapDimensions && mapDimensions.length == 2"
            >
              <span>Width: {{ mapDimensions[0] }}px </span>
              <span> Height: {{ mapDimensions[1] }}px </span>
              <span> Size: {{ fileSize(this.file.size) }}</span>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn @click="createMap" :loading="isAppLoading"> Create New Map </v-btn>
          <v-btn @click="dialog = false"> Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { rules } from "@/store/util/rules.js";

export default {
  props: {
    activatorIcon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      file: null,
      mapName: null,
      mapDimensions: null,
      valid: null,
      rules,
    };
  },
  watch: {},
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    createMap() {
      if (!this.$refs.form.validate()) {
        this.addMessage("Please check your input");
        return;
      }

      const width = this.mapDimensions[0]
      const height = this.mapDimensions[1]

      const params = {
        name: this.mapName,
        mapWidth: width,
        mapHeight: height,
      };

      this.$axios
        .post("/map", params)
        .then((response) => {
          const map = response.data;
          this.uploadFile(map)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadFile(map) {
      this.addMessage("Uploading map...")
      let formData = new FormData();
      formData.append("file", this.file);

      this.$axios
        .post("/map/upload/" + map.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          const f = resp.data;
          map.fileId = f.id;
          this.$emit("uploaded", map);
          this.dialog = false;
          this.addSuccessMessage("Finished uploading map")
        })
        .catch((error) => {
          this.addErrorMessage(error.message);
        });
    },
    getImageDimensions() {
      console.log("getImageDimensions");
      let reader = new FileReader();

      reader.readAsDataURL(this.file);
      reader.onload = async (evt) => {
        let img = new Image();
        img.src = evt.target.result;
        img.onload = async () => {
          this.mapDimensions = [img.width, img.height];
        };
      };
    },
    fileSize(size) {
      if (size) {
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return (
          (size / Math.pow(1024, i)).toFixed(2) * 1 +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
        );
      }
      return "0 B";
    },
  },
};
</script>

<style>
</style>